import React from 'react';
import * as THREE from 'three';
import smoke from '../assets/smoke.png';
import styled from 'styled-components';
import { Texture } from 'three';
const CanavsContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
interface CanvasProps {
  setIsLoading: () => void;
}
const Canvas: React.FC<CanvasProps> = (props) => {
  const { useRef, useEffect, useState } = React;
  const mount = useRef<any>(null);
  const [isAnimating, setAnimating] = useState(true);
  const controls = useRef(null);
  useEffect(() => {
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
    let frameId;
    let composer;
    const cloudParticles = [];
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
    camera.position.z = 1;
    camera.rotation.x = 1.16;
    camera.rotation.y = -0.12;
    camera.rotation.z = 0.27;
    let ambient = new THREE.AmbientLight(0x555555);
    scene.add(ambient);
    let directionalLight = new THREE.DirectionalLight(0xff8c19);
    directionalLight.position.set(0, 0, 1);
    scene.add(directionalLight);

    let orangeLight = new THREE.PointLight(0xcc6600, 30, 350, 1.7);
    orangeLight.position.set(200, 500, 100);
    scene.add(orangeLight);
    let redLight = new THREE.PointLight(0xd8547e, 30, 350, 1.7);
    redLight.position.set(50, 50, 100);
    scene.add(redLight);
    let blueLight = new THREE.PointLight(0x3677ac, 30, 350, 1.7);
    blueLight.position.set(300, 300, 200);
    scene.add(blueLight);
    let whitLight = new THREE.PointLight(16118758, 30, 350, 1.7);
    whitLight.position.set(50, 50, 100);
    scene.add(whitLight);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    scene.fog = new THREE.FogExp2(6332359, 0.0005);
    renderer.setClearColor(scene.fog.color);
    let loader = new THREE.TextureLoader();
    loader.load(smoke, function (texture: Texture) {
      const cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
      const cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
      });

      for (let p = 0; p < 20; p++) {
        let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
        if (p % 2) {
          cloud.position.set(Math.random() * 800 - 400, 800, Math.random() * 250);
        } else {
          cloud.position.set(Math.random() * 800, 200, Math.random() * 500);
        }
        cloud.rotation.x = 1.16;
        cloud.rotation.y = -0.12;
        cloud.rotation.z = Math.random() * 2 * Math.PI;
        cloud.material.opacity = 0.3;
        cloudParticles.push(cloud);
        scene.add(cloud);
      }
    });

    loader.load(smoke, function (texture) {
      const cloudGeo = new THREE.PlaneBufferGeometry(250, 250);
      const cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
      });

      for (let p = 0; p < 500; p++) {
        let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
        if (p % 2) {
          cloud.position.set(Math.random() * 800 - 500, 100, Math.random() * 250);
        } else {
          cloud.position.set(Math.random() * 800, 500, Math.random() * 500);
        }
        cloud.rotation.x = 1.16;
        cloud.rotation.y = -0.12;
        cloud.rotation.z = Math.random() * 2 * Math.PI;
        cloud.material.opacity = 0.3;
        cloudParticles.push(cloud);
        scene.add(cloud);
      }
    });
    const renderScene = () => {
      renderer.render(scene, camera);
      cloudParticles.forEach((p) => {
        p.rotation.z -= 0.001;
      });
    };

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    };

    const animate = () => {
      renderScene();
      frameId = window.requestAnimationFrame(animate);
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
        props.setIsLoading();
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    };

    mount.current.appendChild(renderer.domElement);
    window.addEventListener('resize', handleResize);
    start();

    controls.current = { start, stop };

    return () => {
      stop();
      window.removeEventListener('resize', handleResize);
      mount.current.removeChild(renderer.domElement);
    };
  }, []);

  useEffect(() => {
    if (isAnimating) {
      controls.current.start();
    } else {
      controls.current.stop();
    }
  }, [isAnimating]);

  return <CanavsContainer className="vis" ref={mount} />;
};

export default Canvas;
