import React, { Fragment, useState } from 'react';
import './style.css';
import { GlobalStyle, MainContainer, StyledApp, TitleContainer } from './styles';
import Spinner from '../../../website/src/component/spinner';
import Canvas from './canavs';
import logo from '../assets/logo.svg';
export const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const urlParams = new URLSearchParams(window.location.search);
  const stateParam = urlParams.get('state');
  let serviceName = 'wing';

  if (stateParam) {
    const decodedState = atob(stateParam)
    try {
      const jsonParsed = JSON.parse(decodedState)
      const jsonService = jsonParsed.service
      if (jsonService) {
        serviceName = jsonService
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Fragment>
      <GlobalStyle />
      <Spinner isLoading={isLoading} />
      <StyledApp isLoading={isLoading}>
        <Canvas setIsLoading={() => setIsLoading(false)} />
        <MainContainer>
          <img src={logo} alt={'wing logo'} />
          <TitleContainer>
            <>Thank you for registering</>
            <p>
              Success connecting to<span style={{ textTransform: 'capitalize' }}> {serviceName}</span>.{' '}
            </p>
          </TitleContainer>
        </MainContainer>
      </StyledApp>
    </Fragment>
  );
};

export default App;
