import styled, { createGlobalStyle, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html{
    height:100%
  }
  body {
    margin: 0;
    height:100%
}
    `;

export const StyledApp = styled.div`
  font-family: sans-serif;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  visibility: ${(props: { isLoading: boolean }) =>
    props.isLoading ? 'hidden' : 'unset'};
`;
export const MainContainer = styled.div`
  z-index: 9;
  flex: 1 0 auto;
  margin-block-start: 6vh;
  flex-direction: column;
  display: flex;
  img {
    width: 49%;
    align-self: center;
  }
`;
export const TitleContainer = styled.div`
  font-size: 2rem;
  margin: auto;
  margin-block-start: 7vh;
  font-family: PT Sans;
  text-align: center;
  margin-bottom: 0;
  @media only screen and (max-width: 743px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
`;
export const NoteContainer = styled.div`
  display: flex;
  margin-block-start: 1rem;
  margin: auto;
  margin-top: 0;
  font-family: PT Sans;
  font-size: 1.5rem;
  img {
    width: 1.5rem;
    margin-inline-start: 1rem;
  }
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 557px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
export const TitleFormContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-block-end: 1rem;
  border-bottom: 1px solid rgba(217, 217, 217, 0.3);
`;
export const TitleFormName = styled.span`
  font-family: 'Lora';
  font-size: 2rem;
  color: rgba(26, 115, 232, 0.5);
  margin: auto;
`;
export const CloseForm = styled.span`
  font-family: 'Lora';
  font-size: 0.89rem;
  &:hover {
    cursor: pointer;
    color: rgba(26, 115, 232, 0.5);
  }
`;

export const FormContainer = styled.div`
  margin-block-start: 1rem;
  padding-inline-end: 2rem;
  padding-inline-start: 2rem;
`;
export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FormRow = styled.div`
  display: flex;
  margin-block-end: 1rem;
  img {
    width: 1.5rem;
    background: #efefef;
    padding: 0.25rem;
  }
  input {
    width: 90%;
    border: 1px solid rgba(133, 133, 133, 0.5);
  }
  textarea {
    width: 90%;
    border: 1px solid rgba(133, 133, 133, 0.5);
    min-height: 80px;
  }
`;
export const FormBtn = styled.button`
  background: rgba(1, 122, 255, 0.7);
  color: white;
  font-family: 'Lora';
  font-size: 1.5rem;
  border-radius: 0.5rem;
  width: 72%;
  margin: auto;
`;
export const FooterContainer = styled.div`
  z-index: 9;
  padding-block-end: 2rem;
  padding-block-start: 2rem;
  background: rgba(26, 115, 232, 0.05);
  width: 100%;
  display: flex;
  span {
    padding-inline-start: 5rem;
    font-family: pt;
    color: white;
  }
  @media only screen and (max-width: 480px) {
    font-size: 0.5rem;
  }
`;
const spin = keyframes`
 from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
const spinBack = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
 `;
export const SpinnerContainer = styled.div`
  display: ${(props: { isLoading: boolean }) =>
    props.isLoading ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  height: inherit;
`;
export const ReverseSpinner = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  border: 4px solid transparent;
  border-top-color: #1976d2;
  border-left-color: #1976d2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: ${spin} 1.5s linear infinite;
  &:before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: '';
    border: 4px solid transparent;
    border-top-color: #03a9f4;
    border-left-color: #03a9f4;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: ${spinBack} 1s linear infinite;
  }
`;
export const FormError = styled.div`
  color: red;
  margin-inline-start: 1rem;
`;
