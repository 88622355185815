import React, { Fragment } from 'react';
import { ReverseSpinner, SpinnerContainer } from './styles';
interface SpinnerProps {
  isLoading: boolean;
}
const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <SpinnerContainer isLoading={props.isLoading}>
      <ReverseSpinner />
    </SpinnerContainer>
  );
};

export default Spinner;
