import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html{
    height:100%
  }
  body {
    margin: 0;
    height:100%
}
    `;
export const StyledApp = styled.div`
  font-family: sans-serif;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  visibility: ${(props: { isLoading: boolean }) => (props.isLoading ? 'hidden' : 'unset')};
`;
export const MainContainer = styled.div`
  z-index: 9;
  flex: 1 0 auto;
  margin-block-start: 6vh;
  flex-direction: column;
  display: flex;
  img {
    width: 49%;
    align-self: center;
  }
`;
export const TitleContainer = styled.div`
  font-size: 2rem;
  margin: auto;
  margin-block-start: 7vh;
  font-family: PT Sans;
  text-align: center;
  margin-bottom: 0;
  @media only screen and (max-width: 743px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
`;
